<template>
  <div>
    <el-table :data="tableData" border stripe v-loading="loading" class="custom-table"
      header-row-class-name="custom-header-row" :span-method="arraySpanMethod" cell-class-name="custom-cell-"
      :row-class-name="rowClassName" @cell-dblclick="cellDblclick">
      <el-table-column prop="row" v-if="tempInfo.type===0" header-align="center" align="center" label="行数"
        min-width="40px">
      </el-table-column>
      <el-table-column prop="high" header-align="center" align="center" label="层数" min-width="40px">
      </el-table-column>
      <el-table-column v-for="(item,index) in cols" :key="index" :prop="item.field" header-align="center" align="center"
        min-width="40px" :label="item.name">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row[scope.column.property].title"
            placement="top-start">
            <span
              :style="scope.row|colorFilter(scope.column.property,tempInfo)">{{scope.row[scope.column.property].value}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-table :data="sumData" border stripe v-loading="loading" class="custom-table" cell-class-name="custom-cell"
      header-row-class-name="custom-header-row" :span-method="arraySpanMethod1">
      <el-table-column v-for="(item,index) in sumCols" :key="index" :prop="item.field" header-align="center"
        min-width="40" align="center" :label="item.name">
        <template slot-scope="scope">
          <span>{{scope.row[scope.column.property]}}</span>
        </template>
      </el-table-column>
    </el-table>
    <template v-if="printArchives">
      <el-table :data="archivesData" border stripe v-loading="loading" class="custom-table"
        cell-class-name="custom-cell" header-row-class-name="custom-header-row" :show-header="false"
        :span-method="arraySpanMethod1">
        <el-table-column v-for="(item,index) in 7" :key="index" :prop="'field'+(item-1)" header-align="center"
          min-width="40" align="center" :label="'field'+(item-1)">
          <template slot-scope="scope">
            <span>{{scope.row[scope.column.property]}}</span>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
  import {
    wareCol,
    wareData,
    tablePlaneColor,
    wareSumCol,
    wareSumData,
    archivesData,
    siloCol,
    siloData
  } from "@/common/index.js"
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      printArchives: {
        type: Boolean,
        default: true
      },
      tempInfo: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    watch: {
      tempInfo() {
        this.init()
      }
    },
    filters: {
      colorFilter(row, column, tempInfo) {
        return tablePlaneColor(row[column].oldValue, tempInfo.minimumTemp, tempInfo.highestTemp)
      }
    },
    data() {
      return {
        cols: [],
        archivesData: [],
        sumData: [],
        sumCols: [],
        tableData: [],
      }
    },
    created() {
      if (Object.keys(this.tempInfo).length > 0) {
        this.init()
      }
    },
    methods: {
      //单元格双击事件
      cellDblclick(row, column) {
        this.$emit("cellDblclick", row[column.property])
      },
      // 初始化
      init() {
        if (this.tempInfo.type === 0) {
          this.cols = wareCol(this.tempInfo.col, this.tempInfo.startPointPosition)
          this.tableData = wareData(this.tempInfo)
          this.sumData = wareSumData(this.tempInfo)
          this.sumCols = wareSumCol(this.tempInfo)
        } else {
          this.cols = siloCol(this.tempInfo.col)
          this.tableData = siloData(this.tempInfo)
          this.sumData = wareSumData(this.tempInfo)
          this.sumCols = wareSumCol(this.tempInfo)
        }

        this.archivesData = archivesData(this.tempInfo)
      },
      //合并单元格
      arraySpanMethod({
        rowIndex,
        columnIndex
      }) {
        if (columnIndex === 0 && this.tempInfo.type === 0) {
          if (rowIndex % this.tempInfo.high === 0) {
            return {
              rowspan: this.tempInfo.high,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      },
      arraySpanMethod1({
        rowIndex,
        columnIndex
      }) {
        if (columnIndex === 0) {
          if (rowIndex % 3 === 0) {
            return {
              rowspan: 3,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      },
      //航新样式
      rowClassName({
        rowIndex
      }) {
        if (rowIndex !== 0) {
          if (this.tempInfo.type === 0) {
            if (rowIndex % this.tempInfo.high === 0) {
              return "custom-row"
            }
          } else {
            return "custom-row"
          }
        }

      }
    }
  }
</script>

<style scoped lang="less">
  .custom-table {
    width: 100%;
    border: 2px solid #000000;

    /deep/ th.gutter {
      display: table-cell !important
    }

    /deep/.el-table__header {
      position: relative;
    }
  }
  .custom-table+.custom-table{
    border-top: 0px solid #000000;
  }

  /deep/ .custom-cell {
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    padding: 6rpx 0 !important;
  }

  /deep/ .custom-cell- {
    border-right: 1px solid #000000;
    padding: 6rpx 0 !important;
  }

  td+td /deep/.custom-cell- {
    border-right: 1px solid #000000;
  }

  /deep/ .custom-header-row {
    th {
      border-right: none;
      border-bottom: 2px solid #000;
      background:#e9e9e9;
    }

    th::after {
      position: absolute;
      content: "";
      width: 1px;
      bottom: 0;
      top: 0;
      right: 0;
      z-index: 1;
      background-color: #000000;
    }

    th>.cell {
      padding: 0 !important;
    }
  }


  /deep/ .custom-row td {
    border-top: 2px solid #000000;
  }
</style>
